import { Link } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Layout from "../../include/layout";
import $ from "jquery";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import {  setDataTable } from "../../../../utils/helpers";
import moment from "moment";
import AdministrationService from "../../../../services/AdministrationService";

export default function HistoriqueConnexionsIndex() {
  //##########################/// METHODES ##########################///
  const [historiqueconnexions, setHistoriqueConnexions] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(true);


  //get liste Historiques des connexions
  const fetchHistoriquesconnexions = async () => {
    setLoading(true);

    try {
      const response = await AdministrationService.historique_connexions(token);
      const data = response.data;

      if (response.status === 200) {
        setHistoriqueConnexions(data?.data);
      } else {
        console.error("Erreur dans la réponse:", data.message);
      }
    } catch (error) {
      console.error(error);
    }

    setDataTable();
    setLoading(false);
  };

  


  useEffect(() => {
    fetchHistoriquesconnexions();
    $("#parametres-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{
                isParametre: true,
                libelle: "Historique des connexions",
              }}
              routeName={myroutes.historiqueconnexions_index}
            ></BreadcrumbComponent>


            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font  table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Utilisateur</th>
                        <th scope="col">Nom Prénoms</th>
                        <th scope="col">Rôle</th>
                        <th scope="col">Début session</th>
                        <th scope="col">Fin session</th>
                        <th scope="col">Localisation</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {historiqueconnexions.length > 0 &&
                        historiqueconnexions.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td>
                            {item.get_createdby
                                ? item.get_createdby.email
                                : "---"}
                            </td>
                            <td>
                              {item.get_createdby
                                ? item.get_createdby.nom + " "+ item.get_createdby.prenoms
                                : "---"}
                            </td>
                            <td>
                              {item.get_createdby && item.get_createdby.get_profil_utilisateur.map(option => {
                                return (
                                    <span key={option.id} className="badge bg-primary me-1">
                                        {option.get_profil?.libelle}
                                    </span>
                                    );
                              })}
                            </td>
                            <td>
                              {moment(item.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>
                              {moment(item.end_session).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>{item.connexion_ip} <br /> {item.connexion_type}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
