import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import PaysService from "../../../services/PaysService";
import DossiersService from "../../../services/DossiersService";
import StatutsdossiersService from "../../../services/StatutsdossiersService";
import ObjetsService from "../../../services/ObjetsService";
import { Dialog } from "primereact/dialog";
import ChambresService from "../../../services/ChambresService";
import ProceduresService from "../../../services/ProceduresService";
import {
  afficherOneMessageError,
  convertDateToTexte,
  downloadSingleFile,
  getColorStatus,
  getLatestDate,
  setDataTable,
  sortedDates,
} from "../../../utils/helpers";
import ChambresmembresService from "../../../services/ChambresmembresService";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import { app_url } from "../../../services/http";
import CalendriersService from "../../../services/CalendriersService";
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Editor } from "primereact/editor";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { getButtonConfig } from "../../../utils/ButtonUtils";

export default function DossiersIndex() {
  //##########################/// METHODES ##########################///
  const [dossiers, setDossiers] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const [chambres, setChambres] = useState([]);
  const [chambre_id, setChambre_id] = useState("");
  const [utilisateurs, setUtilisateurs] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [paysTrouve, setPaysTrouve] = useState([]);
  const [statutTrouve, setStatutTrouve] = useState([]);
  const [objets, setObjets] = useState([]);
  const [statutsdossiers, setStatutsdossiers] = useState([]);
  const [dossiersfiles, setDossiersfiles] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [modalLabel, setModalLabel] = useState("");
  const [chambres_membres, setChambresMembres] = useState([]);
  const [modalObjet, setModalObjet] = useState([]);
  const [dossierspartiesdocuments, setDossierspartiesdocuments] = useState([]);
  const [detailLabel, setDetailLabel] = useState("");
  const profils = useSelector((state) => state.auth.profil);
  const [statut_dossier_id, setStatutDossier_id] = useState("");
  const [calendriers, setCalendriers] = useState([]);
  const [calendrier_id, setCalendrier_id] = useState("");
  const [observation, setObservation] = useState("");
  const [dossier_id, setDossier_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [calendriers_available, setCalendriersAvailable] = useState([]);
  const [currentCalendar, setCurrentCalendar] = useState([]);
  const [motif_renvoie, setMotifRenvoie] = useState("");
  const [displayMotifRenvoi, setDisplayMotifRenvoi] = useState(false);
  const [chambresDisponibles, setChambresDisponibles] = useState([]);
  const [displayCalendrier, setDisplayCalendrier] = useState(true);
  const [displayAffection, setDisplayAffectation] = useState(false);

  const [showModal, setShowModal] = useState(false); // État pour contrôler la visibilité du modal
  const [showModalAffectation, setShowModalAffectation] = useState(false);

  const handleShowModal = (reference) => {
    setShowModal(true);
    setModalLabel(reference);
  }; // Fonction pour afficher le modal
  const handleCloseModal = () => setShowModal(false); // Fonction pour fermer le modal

  const handleShowModalAffectation = (option) => {
    //console.log(option)
    //Recupérer la dernière chambre dans laquelle se trouve le dossier
    const lastElement =
      option.get_date_audience[option.get_date_audience.length - 1];
    const filteredData = calendriers_available.filter(
      (opt) => parseInt(opt.chambre_id) == lastElement.chambre_id
    );
    //console.log(filteredData)
    setChambre_id(lastElement.chambre_id);
    setStatutDossier_id(option.statut_dossier_id);
    setShowModalAffectation(true);
    setModalLabel(option.reference_dossier);
    //setCalendriers(filteredData)
    setDossier_id(option.id);

    //Recuperer les calendriers de la chambre courante
    setCalendriers(filteredData);
  };

  const handleChangeChambre = (e) => {
    setChambre_id(e.target.value);
    const filteredData = calendriers_available.filter(
      (opt) => parseInt(opt.chambre_id) == e.target.value
    );
    setCalendriers(filteredData);

    //console.log(filteredData)
  };

  const handleCloseModalAffectation = () => setShowModalAffectation(false);

  const [dossierData, setDossierData] = useState({
    nouveaudossiers: 0,
    dossiersrenvoyes: 0,
    dossiersendelibere: 0,
    dossiersvides: 0,
    dossiersradies: 0,
  });

  const fetchDossierStats = async () => {
    try {
      const response = await DossiersService.countDossiersByStatut(token);
      const responseData = response.data.data;

      setDossierData({
        nouveaudossiers: responseData.nouveaudossiers,
        dossiersrenvoyes: responseData.dossiersrenvoyes,
        dossiersendelibere: responseData.dossiersendelibere,
        dossiersvides: responseData.dossiersvides,
        dossiersradies: responseData.dossiersradies,
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
    }
  };

  //get liste statuts dossiers
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((res) => {
        setStatutsdossiers(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statutsdossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };

  //get liste pays
  const fetchPays = async () => {
    await PaysService.index(token)
      .then((res) => {
        setPays(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste chambres
  const fetchChambres = async () => {
    await ChambresService.index(token)
      .then((res) => {
        setChambres(res.data.data);
        setChambresDisponibles(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste membres chambres
  const fetchChambresMembres = async () => {
    await ChambresmembresService.index(token)
      .then((res) => {
        setChambresMembres(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Retourner les membres distincts d'une chambre à partir de son ID
  const getActeurChambre = (chambreId) => {
    // Filtrer les membres pour ne garder que ceux qui ont le même ID de chambre
    let membres = chambres_membres.filter(
      (opt) => parseInt(opt.chambre_id) === chambreId
    );

    // Créer un tableau pour stocker les noms des membres distincts
    let nomsMembres = [];
    let countMembre = new Set();

    // Parcourir les membres filtrés
    membres.forEach((membre) => {
      // Vérifier si le nom complet du membre n'a pas déjà été ajouté à la liste
      let nomComplet =
        membre.get_utilisateur.nom + " " + membre.get_utilisateur.prenoms;
      if (!countMembre.has(membre.get_utilisateur.id)) {
        // Si l'id de l'utilisateur n'est pas déjà dans la liste, l'ajouter
        nomsMembres.push(nomComplet);
        countMembre.add(membre.get_utilisateur.id);
      }
      /*if (!nomsMembres.includes(nomComplet)) {
              // Si le nom complet n'est pas déjà dans la liste, l'ajouter
              nomsMembres.push(nomComplet);
            }*/
    });

    // Retourner les noms des membres distincts sous forme de chaîne séparée par des virgules
    return nomsMembres.join(", ");
  };

  const getNomPays = (paysId) => {
    const paysTrouve = pays.find((pays) => pays.id === paysId);
    return paysTrouve ? paysTrouve.nom_pays : "N/A";
  };

  //get liste categories dossiers
  const fetchObjets = async () => {
    await ObjetsService.index(token)
      .then((res) => {
        setObjets(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getObjets = (objet_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(objet_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Objet = objets.find((item) => {
      return item.id == idToFind;
    });

    return Objet ? Objet.libelle : "N/A";
  };

  //récupérer les typesprocedures

  const fetchprocedures = async () => {
    await ProceduresService.index(token)
      .then((res) => {
        setProcedures(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const responseData = response.data;

      // Récupération de la liste des statuts
      const responseStatut = await StatutsService.index(token);
      const statutsData = responseStatut.data.data;
      if (
        responseData.data &&
        responseData.data.length > 0 &&
        responseStatut.status == 200
      ) {
        //Afficher les dossiers dont paiement a été effectué
        const statutPayer = statutsData.find(
          (statut) => statut.libelle === "PAYE"
        );
        // Filtrer les dossiers pour ne garder que ceux avec un statut 'PAYE'
        const dossierPayer = responseData.data.filter((option) => {
          return option.get_paiement.some(
            (item) => parseInt(item.statut_id) == statutPayer.id
          ); //7 pour le statut PAYE
        });

        //console.log(statutPayer.id)

        setDossiers(dossierPayer);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de dossiers:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.dossiers_edit, { state: { ref: param } });
  };

  const goToShow = (ref) => {
    navigate(`${myroutes.dossiers_show}/${ref}`);
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        DossiersService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchDossiers();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  //get liste dossiers
  const fetchDossiersfiles = async () => {
    try {
      const response = await DossierspartiesdocumentsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossierspartiesdocuments(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //Afficher le modal de details
  const handleShowDetails = (option) => {
    setDetailLabel(option.reference_dossier);
    const filteredData = dossierspartiesdocuments.filter(
      (opt) => parseInt(opt.dossier_id) === option.id
    );
    setModalObjet(filteredData);
    //console.log(option)
    handleShowModal();
  };

  //get liste chambres
  const fetchCalendriers = async () => {
    await CalendriersService.index(token)
      .then((res) => {
        //Filtrer pour récupérer les dates qui sont à 7j au moins de la date du jour
        const currentDate = new Date();

        const filteredDates = res.data.data.filter((dateString) => {
          const date = new Date(dateString.jour);
          const differenceInTime = date.getTime() - currentDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          return differenceInDays >= 7;
        });

        setCalendriersAvailable(filteredDates);
        //console.log(filteredDates)
        //setCalendriersAvailable(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Save affectation du dossier
  const handleAffecterChambre = async () => {
    handleCloseModalAffectation();
    setLoading(true);
    let form = {
      calendrier_id,
      chambre_id,
      dossier_id,
      observation,
      statut_dossier_id,
    };
    await DatesaudiencesService.store(form, token)
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
        afficherOneMessageError(error.response.data.message);
      });
  };

  const fetchStatuts = async () => {
    await StatutsService.index(token)
      .then((res) => {
        setStatuts(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Effectuer des controles si le statut du dossier change
  const handleSetStatutId = (e) => {
    const newStatutId = e.target.value;
    const newStatutLibelle = getStatutLibelle(newStatutId);

    setStatutDossier_id(newStatutId);
    if (
      newStatutLibelle === "Renvoyé" ||
      newStatutLibelle === "Renvoi pour nouvelle attribution"
    ) {
      setDisplayMotifRenvoi(true);
      setDisplayCalendrier(true);
    } else {
      setDisplayMotifRenvoi(false);

      if (newStatutLibelle === "Mis en délibéré") {
        // On conserve la chambre actuelle du dossier
        setChambres(
          chambres.filter(
            (option) => parseInt(option.id) === parseInt(chambre_id)
          )
        );
        setDisplayCalendrier(true);
      } else {
        setChambres(chambresDisponibles);
        if (newStatutLibelle === "Vidé" || newStatutLibelle === "Radié") {
          setDisplayCalendrier(false);
          $("#calendriers").attr("required", false);
        }
      }
    }
  };

  //Verifie si le dossier est dejà radié ou vidé
  const checkDisplayAffection = (statutDossier_id) => {
    if (
      getStatutLibelle(statutDossier_id) === "Radié" ||
      getStatutLibelle(statutDossier_id) === "Vidé"
    ) {
      return false;
    }

    return true;
  };

  const handleDownload = (path) => {
    //e.preventDefault();
    saveAs(`${app_url}storage/${path}`, path);
  };

  useEffect(() => {
    fetchDossiers();
    fetchStatutsdossiers();
    fetchObjets();
    //fetchPays();
    fetchprocedures();
    fetchDossierStats();
    fetchDossiersfiles();
    fetchChambres();
    fetchChambresMembres();
    fetchCalendriers();
    //fetchStatuts();
    $("#dossiers-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">
                    Liste des dossiers &nbsp;
                    {(profils.includes("SUPERADMIN") ||
                      profils.includes("ADMIN") ||
                      profils.includes("AGEC")) && (
                      <>
                            <Link
                              className={`btn btn-sm btn-${
                                getButtonConfig("CREATE")?.color
                              }`}
                              to={myroutes.creer_dossier}
                              title="Enrôler un dossier"
                            >
                              <i
                                className={`fa ${
                                  getButtonConfig("CREATE")?.icon
                                }`}
                              ></i>
                              &nbsp;Ajouter
                            </Link>{" "}

                            <Link
                              className={`btn btn-sm btn-${
                                getButtonConfig("DELETE")?.color
                              }`}
                              
                              title="Jonction de dossier"
                            >
                              <i
                                className="bi bi-journal-plus"
                              ></i>
                              &nbsp;Jonction de dossier
                            </Link>{" "}
                      </>
                    )}
                  </h1>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 my-2 py-3 align-items-center justify-content-between">
              <div className="col px-3">
                <Link className="text-primaryxx" style={{ color: "#378006" }}>
                  <h2>{dossierData.nouveaudossiers}</h2>
                </Link>
                Nouveau
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-dangerxx" style={{ color: "#dc3545" }}>
                  <h2>{dossierData.dossiersrenvoyes}</h2>
                </Link>
                <span className="">Renvoyé</span>
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-warningxx" style={{ color: "#FF9800" }}>
                  <h2>{dossierData.dossiersendelibere}</h2>
                </Link>
                <span className="">En délibéré</span>
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-successxx" style={{ color: "#5c636a" }}>
                  <h2>{dossierData.dossiersvides}</h2>
                </Link>
                <span className="">Vidé</span>
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-secondaryxx" style={{ color: "#7DCBC9" }}>
                  <h2>{dossierData.dossiersradies}</h2>
                </Link>
                <span className="">Radié</span>
              </div>
            </div>

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-borderedxx caption-top table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <caption className="text-danger fw-bold">
                      {" "}
                      NB: Les dossiers non imprimés ont un arrière-plan jaune.
                    </caption>
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Référence Affaire</th>
                        <th className="">Jugement attaqué</th>
                        <th className="">Date d'audience</th>
                        <th className="">Acteurs autorisés</th>
                        <th className="">Statut</th>
                        <th className="">Type procédure</th>
                        {!profils.includes("PDT") &&
                          !profils.includes("CCC") &&
                          !profils.includes("PC") && (
                            <th className="">Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {dossiers.length > 0 &&
                        dossiers.map((item, index) => (
                          <tr
                            key={index}
                            className={`${
                              item.is_imprime
                                ? "table-default"
                                : "table-warning"
                            }`}
                          >
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              <Link
                                to={`${myroutes.dossiers_show}/${item.ref}`}
                                title="Voir"
                              >
                                <b>{item.reference_dossier} </b>
                              </Link>
                            </td>
                            <td className="cell">
                              {item.jugement_attaque} du{" "}
                              {formatDate(item.date_jugement)} au{" "}
                              {item.get_tribunal?.libelle}
                            </td>
                            <td className="cell">
                              {item &&
                                item.get_date_audience &&
                                item.get_date_audience.length > 0 &&
                                (() => {
                                  const latestDate = getLatestDate(
                                    item.get_date_audience
                                  );
                                  return latestDate;
                                })()}
                            </td>
                            <td className="th-table">
                              <ul className="list-unstyled">
                                {item &&
                                  item.get_date_audience &&
                                  item.get_date_audience.length > 0 &&
                                  item.get_date_audience.map(
                                    (option, index) => (
                                      <li
                                        key={index}
                                        className="justify-content-center "
                                      >
                                        {option.get_calendrier &&
                                          getActeurChambre(
                                            option.get_calendrier.chambre_id
                                          )}
                                      </li>
                                    )
                                  )}
                              </ul>
                            </td>

                            <td className="cell">
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: getColorStatus(
                                    item.statut_dossier_id
                                  ),
                                }}
                              >
                                {getStatutLibelle(item.statut_dossier_id)}
                              </span>
                            </td>

                            <td className="cell">
                              <span className="badge bg-secondary">
                                {item.get_procedure.get_type_procedure
                                  ? item.get_procedure.get_type_procedure
                                      .libelle
                                  : "N/A"}
                              </span>
                              <span className="badge bg-primary">
                                {item.parent_id && "PROCEDURE DEP"}
                              </span>
                            </td>

                            {!profils.includes("PDT") &&
                              !profils.includes("CCC") &&
                              !profils.includes("PC") && (
                                <td className="cell ">
                                  <div className="d-flex">
                                    {/***
                                                                 * <Button className="btn btn-sm btn-primary icon-font me-2 mb-2 " onClick={() => goToEdit(item.ref)} title="Modifier">
                                                                    <i className="bi bi-pen-fill"></i></Button>
                                                                */}

                                    <Button
                                      className="btn btn-sm btn-info icon-font me-2 mb-2 "
                                      onClick={() => goToShow(item.ref)}
                                      title="Consulter le dossier"
                                    >
                                      <i className="bi bi-eye-fill text-white h6"></i>
                                    </Button>
                                    <Button
                                      variant="secondary"
                                      className="icon-font me-2 mb-2"
                                      onClick={() => handleShowDetails(item)}
                                      title="Pièces jointes du dossier"
                                    >
                                      <i className="bi bi-file-earmark-font-fill h6"></i>
                                    </Button>
                                    {/*
                                                                    <Button className="btn btn-sm btn-danger icon-font me-2 mb-2" onClick={() => onDelete(item.ref)} title="Supprimer">
                                                                        <i className="bi bi-trash"></i></Button>
                                                                */}
                                    {(profils.includes("GEC") ||
                                      profils.includes("GCH") ||
                                      profils.includes("ADMIN")) &&
                                      checkDisplayAffection(
                                        item.statut_dossier_id
                                      ) && (
                                        <>
                                          <Button
                                            className="btn btn-sm btn-success icon-font me-2 mb-2"
                                            onClick={() =>
                                              handleShowModalAffectation(item)
                                            }
                                            title="Affecter le dossier"
                                          >
                                            <i className="bi bi-arrow-up-right-square h6"></i>
                                          </Button>
                                        </>
                                      )}
                                  </div>
                                </td>
                              )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
            {/* Modal */}
            <Dialog
              header={"Affecter le dossier " + modalLabel + " à une chambre"}
              visible={showModalAffectation}
              onHide={handleCloseModalAffectation}
              style={{
                maxWidth: "700px",
                minHeight: "250px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
            >
              {/* Contenu de votre modal */}
              <Col md={12} sm={12} className="formx-groupx">
                <Form.Group className="mb-3 mt-4">
                  <Form.Label className="fw-bold">
                    Statut du dossier <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Select
                    className=""
                    id="statut_dossier_id"
                    name="statut_dossier_id"
                    value={statut_dossier_id}
                    required
                    onChange={handleSetStatutId}
                  >
                    <option value="" selected>
                      -- Statut du dossier --
                    </option>
                    {statutsdossiers.length > 0 &&
                      statutsdossiers.map((item) =>
                        item.libelle == "Nouveau" ? (
                          <>
                            <option key={item.id} disabled value={item.id}>
                              {item.libelle}
                            </option>
                          </>
                        ) : (
                          <>
                            <option key={item.id} value={item.id}>
                              {item.libelle}
                            </option>
                          </>
                        )
                      )}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} sm={12} className="formx-groupx">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Chambre <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Select
                    className=""
                    id="chambre_id"
                    name="chambre_id"
                    value={chambre_id}
                    required
                    onChange={(e) => {
                      handleChangeChambre(e);
                    }}
                  >
                    <option value="" selected>
                      -- Sélectionnez --
                    </option>
                    {chambres.length > 0 &&
                      chambres.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.libelle}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {displayCalendrier && (
                <>
                  <Col
                    md={12}
                    sm={12}
                    className="formx-groupx"
                    id="calendriers"
                  >
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Calendrier <i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Select
                        className=""
                        id="calendrier_id"
                        name="calendrier_id"
                        value={calendrier_id}
                        required
                        onChange={(e) => {
                          setCalendrier_id(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          -- Sélectionnez --
                        </option>
                        {calendriers.length > 0 &&
                          sortedDates(calendriers).map((item) => (
                            <option key={item.id} value={item.id}>
                              {convertDateToTexte(item.jour)}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </>
              )}

              {displayMotifRenvoi && (
                <>
                  <Row>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Motif de renvoi <i className="text-danger"></i>{" "}
                          <i
                            className="text-info bi bi-question-circle-fill"
                            title="Motif de renvoi du dossier"
                          ></i>
                        </Form.Label>
                        <Editor
                          value={motif_renvoie}
                          onTextChange={(e) => setMotifRenvoie(e.htmlValue)}
                          style={{ height: "100px" }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              <div className="p-fluid">
                <Col md={12} sm={12} className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Observation <i className="text-danger"></i>
                    </Form.Label>
                    <textarea
                      value={observation}
                      name="resume"
                      id=""
                      cols="30"
                      onChange={(e) => setObservation(e.target.value)}
                      rows="5"
                      className="form-control summernote border border rounded-2"
                      placeholder=""
                    ></textarea>
                  </Form.Group>
                </Col>
              </div>

              <div className="p-dialog-footerx mt-4">
                {/* Pied de page du modal */}
                <Button
                  type="submit"
                  variant="warning me-3"
                  onClick={handleAffecterChambre}
                >
                  Enregistrer
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCloseModalAffectation}
                >
                  Annuler
                </Button>
                {/* Autres boutons ou actions si nécessaire */}
              </div>
            </Dialog>

            {/* Modal */}
            <Dialog
              header={"Détails sur l'affaire " + detailLabel}
              visible={showModal}
              maximizable
              onHide={handleCloseModal}
              style={{
                width: "700px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
            >
              <div className="p-fluid">
                <div className="row">
                  <hr />
                  <p className="text-center fw-bold">
                    Documents relatifs aux dossiers{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                {modalObjet.map((item, index) => (
                  <>
                    <p>
                      <b>Auteur : </b> {item.get_createdby.nom} <br />
                      <b>Type document : </b> {item.get_type_document.libelle}
                      <br />
                      <b>Date d'envoi : </b>{" "}
                      {dayjs(item.created_at).format("DD-MM-YYYY à H:m:s")}
                    </p>
                    <a
                      href="#"
                      onClick={() => downloadSingleFile(item.path, token)}
                      className="text-primary fw-bold"
                      key={index}
                    >
                      Télécharger le fichier
                    </a>
                    <hr />
                  </>
                ))}
              </div>

              <div className="p-dialog-footer mt-3">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Fermer
                </Button>
              </div>
            </Dialog>

            <Modal show={loading} backdrop="static" keyboard={false} centered>
              <Modal.Body className="text-center">
                <ProgressSpinner />
                <p className="my-3 fw-bold" style={{ fontSize: "18px" }}>
                  Veuillez patienter pendant l'enregistrement ...
                </p>
              </Modal.Body>
            </Modal>
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
