import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { myroutes } from "../../../routes/webroute";
import DossiersService from "../../../services/DossiersService";
import StatutsdossiersService from "../../../services/StatutsdossiersService";
import { TabView, TabPanel } from 'primereact/tabview';
import TabResumeDossier from "../../../components/TabResumeDossier";
import TabProcedures from "../../../components/TabProcedures";
import TabAudiences from "../../../components/TabAudiences";
import TabFichiers from "../../../components/TabFichiers";
import TabDiscussions from "../../../components/TabDiscussions";
import TabSaisines from "../../../components/TabSaisines";
import TabPublications from "../../../components/TabPublications";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery"
import { Dialog } from "primereact/dialog";
import { afficherMessagesErreur, afficherMessagesSuccess, afficherOneMessageError } from "../../../utils/helpers";
import { Button } from "react-bootstrap";
import TabCalendriersProcedures from "../../../components/TabCalendriersProcedures";
import Swal from "sweetalert2";




export default function DossiersShow() {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { ref } = useParams(); // référence de l'url
  const [dossier, setDossier] = useState(null); // État pour stocker les détails du dossier
  const [statutsdossiers, setStatutsdossiers] = useState([])
  const [tabs, setTabs] = useState([])
  const profils = useSelector((state) => state.auth.profil);
  const [calendriers_procedures, setCalendriersProcedures] = useState(null);
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [file, setFile] = useState('');
  const [sending, setSending] = useState(false);

  const scrollableTabs = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }))

  /*const tabsContent = [
    { title: <span className="title-tab"><i className="bi bi-card-list me-1"></i> Synthèse du dossier</span>, content: <TabResumeDossier dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-exclamation"></i> Procédures</span>, content: <TabProcedures dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-book-half me-1"></i> Audiences</span>, content: <TabAudiences dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-file-earmark me-1"></i>Fichiers</span>, content: <TabFichiers dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-chat-quote me-1"></i> Discussions/Messages</span>, content: <TabDiscussions dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-clipboard2-data-fill me-1"></i> Saisines</span>, content: <TabSaisines dossier={dossier} /> },
    { title: <span className="title-tab"><i className="bi bi-file-text-fill me-1"></i>Publications(Décisions/Récipissé/...)</span>, content: <TabPublications dossier={dossier} /> },

  ];*/

  const tabsContent = [
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-card-list me-1"></i> Synthèse du dossier
        </span>
      ),
      content: <TabResumeDossier dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-exclamation"></i> Procédures
        </span>
      ),
      content: <TabProcedures dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-calendar-event me-1"></i> Celendriers des procédures
        </span>
      ),
      content: <TabCalendriersProcedures dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-book-half me-1"></i> Audiences
        </span>
      ),
      content: <TabAudiences dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-file-earmark me-1"></i> Fichiers
        </span>
      ),
      content: <TabFichiers dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-clipboard2-data-fill me-1"></i> Saisines
        </span>
      ),
      content: <TabSaisines dossier={dossier} />
    },
    ...(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("GEC") || profils.includes("GCH") ? [
      {
        title: (
          <span className="title-tab">
            <i className="bi bi-chat-quote me-1"></i> Discussions/Messages
          </span>
        ),
        content: <TabDiscussions dossier={dossier} />
      },


      {
        title: (
          <span className="title-tab">
            <i className="bi bi-file-text-fill me-1"></i> Publications (Décisions/Récipissé/...)
          </span>
        ),
        content: <TabPublications dossier={dossier} />

      }
    ] : [])

  ];


  //get liste dossiers details
  const fetchDossierDetails = async () => {
    try {
      const response = await DossiersService.show(ref, token);
      setDossier(response.data.data.dossier);
      setCalendriersProcedures(response.data.data.calendrierprocedure)
      // alert(response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des détails du dossier:", error);
    }
  };

  //get liste statuts dossiers
  const fetchStatutsdossiers = async () => {
    try {
      const response = await StatutsdossiersService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setStatutsdossiers(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSendDecision = async () => {
    if (!file) return
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dossier_ref", dossier.ref);
    setSending(true)

    try {
      const response = await DossiersService.sendDecisionNumerique(formData, token);
      if (response.status === 200) {
        setSending(false)
        //fetchDossierDetails();

        afficherMessagesSuccess(response.data.message || "Bravo ! Document envoyé avec succès");
        setTimeout(() => {
          window.location.reload()
        }, 1500);

      } else {
        setSending(false)
        afficherOneMessageError(response.data.message || "Une erreur s'est produite lors de l'envoie du document.")
      }
    } catch (error) {
      setSending(false)
      Swal.fire({
        icon: 'error',
        title: 'Erreur de validation',
        html: error.response.data.data,
        toast: true,
        position: "top-end",
        timer: 10000,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        timerProgressBar: true,
      });

    }
  }

  useEffect(() => {
    fetchDossierDetails();
    //fetchStatutsdossiers();
    $("#dossiers-nav").addClass("show")
  }, [ref]);


  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Gestion des dossiers / Détails du dossier</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to={myroutes.dossiers_index}>Dossiers</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row g-3 mb-0  align-items-center justify-content-between">
              <div
                className="card bg-successx p-2" style={{ color: "#264f52", backgroundColor: "#3b7d821e", border: "1px solid white" }}>
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="pt-1">
                      <i className="bi bi-folder"></i> <span className="me-3"> {dossier && dossier.reference_dossier} </span>
                      <span className="badge text-bg-success">{dossier && dossier.get_statut_dossier && dossier.get_statut_dossier.libelle}</span>
                      {(profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) && <>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-md btn-warning" onClick={() => handleShowModal()}>
                          <i className="fas fa-file-import"></i>&nbsp;Envoyer la décision numérique
                        </button>
                      </>}
                    </h4>

                  </div>
                  {(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("GEC") || profils.includes("GCH")) &&
                    <>
                      <div className="col-md-3" style={{ textAlign: "right" }}>
                        <div className="dropdown">
                          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Plus d'options
                          </button>
                          <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" to={myroutes.dossiers_index}>Liste des dossiers</Link></li>
                            <li><Link className="dropdown-item" to={myroutes.saisines_index}>Liste des saisines</Link></li>
                            <li><Link className="dropdown-item" to={myroutes.datesaudiences_index}>Dossiers aux rôles</Link></li>
                          </ul>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="card" style={{ fontFamily: "montserrat" }}>
              <TabView scrollable>
                {tabsContent.map((tab, index) => (
                  <TabPanel key={index} header={tab.title} headerStyle={{ color: 'red' }} headerClassName="custom-tab-header">
                    {tab.content}
                  </TabPanel>
                ))}
              </TabView>
            </div>

          </div>

          {/* Modal */}
          <Dialog
            header={"Formulaire d'envoi de la décision numérique au GEC"}
            visible={showModal}
            onHide={handleCloseModal}
            style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
            headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
          >

            <div className="p-fluid">
              <div className="col-md-12 mt-3">
                <label htmlFor="file" className="fw-bold">Document <i className="text-danger">*</i></label>
                <input type="file" className="form-control border-success" accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="file" onChange={handleFileChange} required />
              </div>
            </div>

            <div className="p-dialog-footer mt-3">
              <Button type="button" variant="warning me-3" onClick={handleSendDecision} disabled={sending}>Envoyer</Button>
              <Button variant="secondary" onClick={handleCloseModal}>Fermer</Button>
            </div>
          </Dialog>

        </div>
      </main>
    </Layout>
  );
}
